export const abbreviateMode = (mode: string) => {
    if(mode === 'au2:sydneytrains'){return 'T'}
    if(mode === 'au2:nswtrains'){return 'T'}
    if(mode.includes('train')){return 'T'}
    if(mode === 'au2:nswcoaches'){return 'C'}
    if(mode.includes('coach')){return 'C'}
    if(mode.includes('bus')){return 'B'}
    if(mode.includes('ferry')){return 'F'}
    if(mode.includes('ferries')){return 'F'}
    if(mode.includes('lightrail')){return 'L'}
    if(mode.includes('metro')){return 'M'}
    return mode.replace('au2:', '');
}

export const getModeIcon = (mode: string) => {
    if(mode === 'au2:sydneytrains'){return 'https://anytrip.com.au/img/tfnsw/train.svg'}
    if(mode === 'au2:nswtrains'){return 'https://anytrip.com.au/img/tfnsw/train.svg'}
    if(mode.includes('train')){return 'https://anytrip.com.au/img/tfnsw/train.svg'}
    if(mode === 'au2:nswcoaches'){return 'https://anytrip.com.au/img/tfnsw/coach.svg'}
    if(mode.includes('coach')){return 'https://anytrip.com.au/img/tfnsw/coach.svg'}
    if(mode.includes('bus')){return 'https://anytrip.com.au/img/tfnsw/bus.svg'}
    if(mode.includes('ferry')){return 'https://anytrip.com.au/img/tfnsw/ferry.svg'}
    if(mode.includes('ferries')){return 'https://anytrip.com.au/img/tfnsw/ferry.svg'}
    if(mode.includes('lightrail')){return 'https://anytrip.com.au/img/tfnsw/lightrail.svg'}
    if(mode.includes('metro')){return 'https://anytrip.com.au/img/tfnsw/metro.svg'}
    if(mode.includes('park')){return 'https://anytrip.com.au/img/tfnsw/car-park.svg'}
    if(mode.includes('taxi')){return 'https://anytrip.com.au/img/tfnsw/taxi.svg'}
    return mode.replace('au2:', '');
}

export const abbreviateModeSummary = (modes: string[]) => {
    return [...new Set(modes.map(m => abbreviateMode(m)))]
}

export const getModeIcons = (modes: string[]) => {
    return [...new Set(modes.map(m => getModeIcon(m)))]
}

export const isProd = window.location.host.indexOf('.prod.') > -1;