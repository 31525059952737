import * as React from "react";
import { Admin, Resource} from 'react-admin';
// import { Amplify } from "@aws-amplify/core";
import { buildAuthProvider } from "react-admin-amplify";
import { ScreenCreate, ScreenEdit, ScreenList } from "./components/screens";
import { VariantCreate, VariantEdit, VariantList } from "./components/variant";
import combinedDataProvider from "./combined.provider";
import { Auth } from "@aws-amplify/auth";
import { buildSsoAuthProvider, SsoLoginPage } from "./ssoauth.provider0";
import { AnyTripLayout } from "./components/Layout";
import { isProd } from "./anytrip.utils";

if(isProd){
    Auth.configure({
        region: "ap-southeast-2",
        userPoolId: "ap-southeast-2_wOZpCJROq",
        userPoolWebClientId: "3vb28n57j0e8d9uek56kr188do",
        oauth: {
            domain: "anytrip-nswtci-prod.auth.ap-southeast-2.amazoncognito.com",
            scope: ["email", "openid"],
            redirectSignIn: `${window.location.protocol}//${window.location.host}`,
            redirectSignOut: `${window.location.protocol}//${window.location.host}`,
            responseType: "code",
        }
    })
}else{
    Auth.configure({
        region: "ap-southeast-2",
        userPoolId: "ap-southeast-2_5lgnFAyG4",
        userPoolWebClientId: "54lq9cecc0i0n91g3ccq0urh25",
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    })
}

// Amplify.configure({
//   Auth: {
//     region: "ap-southeast-2",
//     userPoolId: "ap-southeast-2_5lgnFAyG4",
//     userPoolWebClientId: "54lq9cecc0i0n91g3ccq0urh25",
//     authenticationFlowType: 'USER_PASSWORD_AUTH',
//   }
// })

const App = () => (
      <Admin layout={AnyTripLayout} loginPage={isProd ? SsoLoginPage : undefined} dataProvider={combinedDataProvider} authProvider={isProd ? buildSsoAuthProvider({ authGroups: [] }) : buildAuthProvider({ authGroups: [] })}>
          <Resource name="screens" list={ScreenList} edit={ScreenEdit} create={ScreenCreate} />
          <Resource name="stops" />
          <Resource name="variants" list={VariantList} edit={VariantEdit} create={VariantCreate} />
      </Admin>
  );

export default App;