// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import MUList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
// import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OpenInNew from '@material-ui/icons/OpenInNew';
import CastConnected from '@material-ui/icons/CastConnected';
import Info from '@material-ui/icons/Info';
import Cached from '@material-ui/icons/Cached';


// import InboxIcon from '@material-ui/icons/Inbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
import { List, Datagrid, TextField, BooleanField, Edit, TextInput, BooleanInput, NumberInput, SimpleFormIterator, ArrayInput, ReferenceArrayInput, AutocompleteArrayInput, Create, CreateProps, TabbedForm, FormTab, SelectInput, ReferenceInput, Pagination, PaginationProps, Toolbar, ToolbarProps, SaveButton, DeleteButton } from 'react-admin';
import { getModeIcons, isProd } from "../anytrip.utils";

import { apiAxios } from '../combined.provider';
import useSWR from 'swr';
import { useMemo, useState } from 'react';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        //   width: '100%',
        //   maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
const fetcher = (url: string) => apiAxios.get(url).then(res => res.data)

const ScreenListPagination = (props: PaginationProps) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;


export const ScreenList = (props: any) => (
    <List {...props} sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={!isProd} pagination={<ScreenListPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" sortable={false} />
            <TextField source="name" />
            <TextField source="location" />
            <BooleanField source="enabled" />
            <BooleanField source="blank" />
            <BooleanField source="audio" />
        </Datagrid>
    </List>
);

const ScreenEditToolbar = (props: ToolbarProps) => {
    return <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        {!isProd && <DeleteButton undoable={false} />}
    </Toolbar>
};

const commonEditor = <TabbedForm redirect="edit" toolbar={<ScreenEditToolbar />}>
    <FormTab label="Location">
        <TextInput source="name" fullWidth />
        <TextInput source="location" fullWidth multiline helperText="Text description of the screen's location" />
        <ReferenceArrayInput
            source="stopIds"
            reference="stops"
            label="Stops"
            filterToQuery={(searchText: string) => {
                return { fullName: searchText }
            }}
        >
            <AutocompleteArrayInput optionText={(record: any) => {
                return record ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {getModeIcons(record.modes).map((imgUrl: string) => {
                        return <img src={imgUrl} style={{ width: 18, height: 18 }} alt="Mode icon" />;
                    })}
                    <span>&nbsp;{record.fullName}, {record.locality || ''}</span>
                </div> : 'Loading...'
            }} helperText="Select stops by using autocomplete" matchSuggestion={(_: any) => true} />
        </ReferenceArrayInput>
        <NumberInput source="stopRadius" label="Stop radius (metres)" defaultValue={0} helperText="Include departures from stops up to this number of metres away. 0 = selected stops only." />
        {/* <CheckboxGroupInput source="modeIds" choices={[
        { id: 'au2:nswtrains', name: 'NSW Trains' },
        { id: 'au2:nswcoaches', name: 'NSW Trains Coaches' },
        { id: 'au2:sydneytrains', name: 'Sydney Trains' },
        { id: 'au2:metro', name: 'Sydney Metro' },
        { id: 'au2:buses', name: 'Buses' },
        { id: 'au2:schoolbuses', name: 'School Buses' },
        { id: 'au2:privatebuses', name: 'Private Buses' },
        { id: 'au2:lightrail', name: 'Light Rail' },
    ]} /> */}
    </FormTab>
    <FormTab label="Config">
        <BooleanInput defaultValue={true} source="enabled" />
        <BooleanInput source="blank" />
        <TextInput source="blankText" parse={(v: any) => {
            if (v === null) {
                return ''
            }

            return v;
        }} fullWidth multiline helperText="Optional, text to display when screen is blank" />
        <BooleanInput source="audio" />
        <NumberInput source="audioMaxFrequency" min={0} step={1} helperText="The minimum duration (in seconds) between announcements" />
        <NumberInput source="audioWaitBeforeRepeating" min={0} step={1} helperText="The minimum duration (in seconds) before the same announcement is repeated" />
        <NumberInput source="offlineCount" />
        <NumberInput source="screenDiagonalInches" max={100} min={32} step={1} />
    </FormTab>
    <FormTab label="Screen variants">
        <ArrayInput source="views">
            <SimpleFormIterator>
                <ReferenceInput
                    label="Variant"
                    source="type"
                    reference="variants"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <NumberInput source="displaySeconds" defaultValue={30} />
            </SimpleFormIterator>
        </ArrayInput>
    </FormTab>
</TabbedForm>

const Aside = ({ record }: any) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const { data } = useSWR<Array<{
        id: string,
        screenUuid: string,
        ip: string,
        createdAt: string,
        bundleName: string
    }>>('/clients', fetcher, {
        refreshInterval: 3000,
    })

    const reloadClient = async (id: string) => {
        if (window.confirm('Do you wish to reload this connection?  The browser for this device will reload and reconnect.')) {
            const { data } = await apiAxios.post('/clients/' + id + '/reload');
            if (data.success) {
                alert('Successfully reloaded connection');
            } else {
                alert('Error, could not reload connection.');
            }
        }
    }

    const connectedScreens = useMemo(() => {
        return data ? data.filter((v: any) => v.screenUuid === record?.id) : null
    }, [data, record?.id]);
    return <div style={{ width: 250, marginLeft: '1em' }}>
        <div className={classes.root}>
            {/* <Typography variant="h6">Screen</Typography> */}
            <MUList subheader={
                <ListSubheader component="div">
                    Screen
                </ListSubheader>
            }>
                <ListItem button component="a" href={`https://client.${window.location.host.indexOf('.uat.') > -1 ? 'uat' : window.location.host.indexOf('.dev.') > -1 ? 'dev' : 'prod'}.nswtci.anytrip.com.au/remote/${record?.id}`} target="_blank" rel="noreferrer">
                    <ListItemIcon>
                        <OpenInNew />
                    </ListItemIcon>
                    <ListItemText primary="Launch screen" />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Info />
                    </ListItemIcon>
                    <ListItemText primary="Version" secondary={record?.version} />
                </ListItem>
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        <CastConnected />
                    </ListItemIcon>
                    <ListItemText primary="Connections" secondary={connectedScreens != null ? connectedScreens.length : ''} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <MUList component="div" disablePadding>
                        {(connectedScreens || []).map((screen, i) => <ListItem className={classes.nested} key={i}>
                            <ListItemText primary={screen.ip} secondary={<div>
                                <div>{moment(screen.createdAt).fromNow()}</div>
                                <div>{screen.bundleName}</div>
                            </div>} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="reload" onClick={() => reloadClient(screen.id)}><Cached /></IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>)}
                    </MUList>
                </Collapse>
            </MUList>
        </div>
    </div>
};

export const ScreenEdit = (props: any) => (
    <Edit aside={<Aside />} {...props}>
        {commonEditor}
    </Edit>
);

export const ScreenCreate = (props: CreateProps) => (
    <Create {...props}>
        {commonEditor}
    </Create>
);