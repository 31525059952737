// in src/MyAppBar.js
import * as React from 'react';
import { AppBar, AppBarProps, Layout, LayoutProps } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { isProd } from '../anytrip.utils';

// import Logo from './Logo';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

export const AnyTripAppBar: React.FC<AppBarProps> = props => {
    const classes = useStyles();
    return (
        <AppBar {...props} color={isProd ? "primary" : "secondary"}>
            <img src="/anytrip.png" alt="AnyTrip Logo" style={{width: 30, height: 30, marginRight: 10}} />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export const AnyTripLayout: React.FC<LayoutProps> = (props) => <Layout {...props} appBar={AnyTripAppBar} />;
