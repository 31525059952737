import {
  AuthProvider as AuthProviderInterface,
  // DataProvider as DataProviderInterface,
} from "ra-core";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { useLogin, useNotify, Login, } from 'react-admin';
// import { ClientMetaData } from "@aws-amplify/auth/lib-esm/types";
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Button,
  CardActions,
  CardContent,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { useState } from "react";
import { CognitoUserSession } from "amazon-cognito-identity-js";

export interface AuthProviderOptions {
  authGroups?: string[];
}

const defaultOptions = {
  authGroups: [],
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);


export class AuthProvider {
  public authGroups: string[];

  public constructor(options?: AuthProviderOptions) {
    this.authGroups = options?.authGroups || defaultOptions.authGroups;
  }

  public login = (provider: string = 'tfnsw-azure-ad'): Promise<CognitoUser | unknown> => {
    return Auth.federatedSignIn(
      {
        customProvider: provider
      }
    );
  };

  public logout = async (): Promise<any> => {
    const so = await Auth.signOut();
    return so;
  };

  public checkAuth = async (): Promise<void> => {
    let session: CognitoUserSession;
    
    try{
      session = await Auth.currentSession();
    }catch(e){
      // console.log('clear local storage', e)
      window.localStorage.clear();
      await Auth.signOut();
      throw e;
    }

    if (this.authGroups.length === 0) {
      return;
    }

    const userGroups = session.getAccessToken().decodePayload()[
      "cognito:groups"
    ];

    if (!userGroups) {
      throw new Error("Unauthorized");
    }

    for (const group of userGroups) {
      if (this.authGroups.includes(group)) {
        return;
      }
    }

    throw new Error("Unauthorized");
  };

  public checkError = (): Promise<void> => {
    return Promise.resolve();
  };

  public getPermissions = async (): Promise<string[]> => {
    const session = await Auth.currentSession();

    const groups = session.getAccessToken().decodePayload()["cognito:groups"];

    return groups ? Promise.resolve(groups) : Promise.reject();
  };
}

export function buildSsoAuthProvider(
  options?: AuthProviderOptions
): AuthProviderInterface {
  const authProvider = new AuthProvider(options);

  return {
    login: authProvider.login,
    logout: authProvider.logout,
    checkAuth: authProvider.checkAuth,
    checkError: authProvider.checkError,
    getPermissions: authProvider.getPermissions,
  };
}

export const SsoLoginPage: any = (props: any) => {
  const login = useLogin();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const classes = useStyles(props);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if(loading){return}
    setLoading(true);
    login(e.nativeEvent.submitter.value).catch((e) => {
      // console.log('invalid creds', e)
      notify('Invalid credentials')
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Login>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Typography variant="h5" align="center">
            AnyTrip PIDS
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={loading}
            className={classes.button}
            value="tfnsw-azure-ad"
          >
            {loading ? (
                    <CircularProgress
                        className={classes.icon}
                        size={18}
                        thickness={2}
                    />
                ) : 'Login with TfNSW Azure AD'}
          </Button>
        </CardActions>
        <CardActions>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={loading}
            className={classes.button}
            value="anytrip-pids-nswtci-test"
          >
            {loading ? (
                    <CircularProgress
                        className={classes.icon}
                        size={18}
                        thickness={2}
                    />
                ) : 'Login with AnyTrip Azure AD'}
          </Button>
        </CardActions>
      </form>
    </Login>
  );
};