import { DataProvider, fetchUtils, GetListParams, GetManyParams, GetOneParams } from 'ra-core';
import crudProvider from 'ra-data-nestjsx-crud';
import anytripStopProvider from "./anytrip.provider";
import { Auth } from "@aws-amplify/auth";
import axios from 'axios';

const host = (
    window.location.host.indexOf('.uat.') > -1 ? 'https://anytrip-pids-server-uat.oci.jxeeno.com' :
    window.location.host.indexOf('.dev.') > -1 ? 'https://anytrip-pids-server-dev.oci.jxeeno.com' :
    'https://api.prod.nswtci.anytrip.com.au'
    // 'http://localhost:5010'
    // 'https://anytrip-pids-server-dev.oci.jxeeno.com'
);

export const apiAxios = axios.create({
    baseURL: host
});
apiAxios.interceptors.request.use(async function (options) {
    try{
        const session = await Auth.currentSession();
        options.headers['Authorization'] = `Bearer ${session.getAccessToken().getJwtToken()}`;
        return options;
    }catch(e){
        console.error('axios request session expired', e);
        // window.localStorage.clear();
        await Auth.signOut();
        throw e;
    }
}, function (error) {
    console.log('Request error: ', error);
    return Promise.reject(error);
});

const crud = crudProvider(`${host}`, async (url, options = {}) => {
    try{
        const session = await Auth.currentSession();

        options.user = {
            authenticated: true,
            token: `Bearer ${session.getAccessToken().getJwtToken()}`
        }
    }catch(e){
        console.error('crud request session expired', e)
    }

    return fetchUtils.fetchJson(url, options)
});
// const dataProviders = [
//     { dataProvider: crud, resources: ['screens', 'variants'] },
//     { dataProvider: anytripStopProvider, resources: ['stops'] },
// ];

const combinedDataProvider : DataProvider = {
    ...crud,
    getList: (resource: string, params: GetListParams) => {
        if(resource === 'stops'){
            return anytripStopProvider.getList(resource, params)
        }

        return crud.getList(resource, params);
    },
    getOne: (resource: string, params: GetOneParams) => {
        if(resource === 'stops'){
            return anytripStopProvider.getOne(resource, params)
        }

        return crud.getOne(resource, params);
    },
    getMany: (resource: string, params: GetManyParams) => {
        if(resource === 'stops'){
            return anytripStopProvider.getMany(resource, params)
        }

        return crud.getMany(resource, params);
    }
}
  
export default combinedDataProvider;