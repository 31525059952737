import { DataProvider, fetchUtils, GetListParams } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = 'https://anytrip.com.au/api/v3/region/au2';
const httpClient = fetchUtils.fetchJson;

const anytripStopProvider : DataProvider = {
    getList: (resource: string, params: GetListParams) => {
        const query = {
            q: params.filter.fullName
        }
        const url = `${apiUrl}/search?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.response.stops.map((stop: any) => stop.stop),
            total: json.response.stops.length
        }));
    },

    getOne: async (resource: string, params: any) => {
        return {data: await httpClient(`${apiUrl}/stop/${params.id}`).then(({ json }) => json.response.stop)};
    },

    getMany: async (resource, params) => {
        return { data: await Promise.all(params.ids.map(stopId => {
            return httpClient(`${apiUrl}/stop/${stopId}`).then(({ json }) => json.response.stop);
        }))}
    },

    getManyReference: (resource, params) => {
        return Promise.resolve({data: [], total: 0})
    },

    update: (resource, params) => Promise.resolve({data: ({} as any)}),

    updateMany: (resource, params) => Promise.resolve({data: ([] as any[])}),

    create: (resource, params) => Promise.resolve({data: ({} as any)}),

    delete: (resource, params) => Promise.resolve({data: ({} as any)}),

    deleteMany: (resource, params) => Promise.resolve({data: ([] as any)}),
};

export default anytripStopProvider